import request from '@/utils/request'

export function getUserApi(searchQuery) {
    return request({
      url: '/users',
      method: 'get',
      params: {'s': searchQuery}
    });
}

export function getUserAccounts(id){
    return request({
        url:`/users/${id}`,
        method:'get'
    });
}

export function createUser(data) {
    return request({
      url: '/auth/user',
      method: 'post',
      data
    });
}

export function toggleUserStatusAPI(id, data){
  return request({
    url: `/users/${id}`,
    method: 'patch',
    data
  });
}

export function deleteUserAPI(id){
  return request({
    url: `/users/${id}`,
    method: 'delete'
  });
}

export function toggleAccountStatusAPI(id, data){
  return request({
    url: `/accounts/${id}`,
    method: 'patch',
    data,
  });
}

export function deleteAccountAPI(id){
  return request({
    url: `/accounts/${id}`,
    method:'delete'
  });
}
