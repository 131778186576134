import { login, logout, userInfo } from '@/api/auth'

import { getToken, setToken, removeToken } from '@/utils/auth'

const state = {
    token: getToken(),
    email: '',
    is_superuser: false
};


const mutations = {
    SET_TOKEN:(state, token)=>{
        state.token = token
    },
    SET_EMAIL:(state, email)=>{
        state.email = email
    },
    SET_SUPERUSER(state, isSuperUser){
        state.is_superuser = isSuperUser
    }
};


const actions = {
    login({commit}, loginData){
        const {email, password} = loginData;
        return new Promise((resolve, reject)=>{
            login({email:email.trim(), password:password}).then(res=>{
                const {token, is_superuser} = res.data;
                commit('SET_TOKEN', token);
                commit('SET_SUPERUSER', is_superuser)
                localStorage.setItem('su', is_superuser);
                setToken(token);
                resolve(res.data);
            }).catch(error =>{
                reject(error);
            }); 
        });
    },
    logout({commit}){
        return new Promise((resolve)=>{
            logout().finally(()=>{
                commit('SET_TOKEN', '');
                removeToken();
                localStorage.removeItem('su')
                resolve()
            });
        });
    },
    getUserInfo({commit}){
        return new Promise((resolve, reject)=>{
            userInfo()
            .then((res)=>{
                const {is_superuser} = res.data
                commit('SET_SUPERUSER', is_superuser)
                resolve(res.data)
            }).catch((e)=>{
                reject(e)
            })
        })
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
