import request from '@/utils/request'

export function login(data) {
    return request({
      url: '/auth/login',
      method: 'post',
      data
    });
}

export function logout() {
    return request({
      url: '/auth/logout',
      method: 'get'
    });
}

export function userInfo(){
  return request({
    url:'/auth/user',
    method:'get'
  });
}

export function changePassword(data){
  return request({
    url: '/auth/user/change_password',
    method:'post',
    data,
  });
}
