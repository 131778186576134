<template>
  <div class="">
    <banner />
    <el-button
      @click="signout"
      size="small"
      style="
        position: absolute;
        right: 15px;
        top: 10px;
        color: #000;
        font-weight: bold;
        width: 120px;
        height: 35px;
        font-size: 20px;
        background-color: #00ff00;
        border: 0;
        border-radius: 8px;
        padding-top: 5px;
      "
    >
      Log Out
    </el-button>

    <el-popover placement="bottom" width="400" trigger="click">
      <change-password></change-password>
      <el-button
        slot="reference"
        size="small"
        style="
          position: absolute;
          top: 10px;
          left: 15px;
          height: 35px;
          color: #fff;
          font-weight: bold;
          font-size: 20px;
          padding-top: 5px;
          background: #000;
          border: 2px solid #00ff00;
        "
        >Change Password
        <i class="el-icon-arrow-down" />
      </el-button>
    </el-popover>

    <el-row justify="center" type="flex">
      <el-col :xl="10" :md="10" :lg="10" :sm="18" :xs="24">
        <el-form label-position="top">
          <el-form-item label="My API Key:">
            <el-input :readonly="true" v-model="apiKey">
              <el-button
                ref="copyButton"
                slot="suffix"
                type="primary"
                circle
                style="background: #000; border: 0; padding: 5px"
                ><i class="el-icon-document" style="font-size: 18px"></i>
              </el-button>
            </el-input>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row justify="center" type="flex">
      <el-col :md="13" :lg="12" :sm="12">
        <span
          style="
            margin-bottom: 30px;
            display: block;
            color: #00ff00;
            font-weight: bold;
          "
          >Please do <span style="color: #fff">NOT</span> share your API key
          with anyone.</span
        >
      </el-col>
    </el-row>

    <el-row justify="center" type="flex">
      <el-col :xl="10" :md="10" :lg="10" :sm="16" :xs="22">
        <el-table
          empty-text="No Records"
          :data="accounts"
          :header-row-style="{ fontWeight: 'bold' }"
          :highlight-current-row="false"
        >
          <el-table-column label="Account Number" prop="account">
          </el-table-column>
          <el-table-column width="120">
            <template slot-scope="props">
              <el-button
                style="background: #ff0000; color: #fff"
                @click="onAccountDelete(props.row.id)"
                >Delete</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>

    <el-row justify="center" type="flex" style="margin-top: 70px">
      <el-col :md="13" :lg="12" :sm="12">
        <el-button
          @click="download"
          size="medium"
          style="
            color: #000;
            font-weight: bold;
            font-size: 18px;
            background-color: #00ff00;
            border: 0;
            border-radius: 8px;
          "
        >
          <div>
            <div style="display: inline-flex; align-items: center">
              DOWNLOAD THE POW BANKER EA
              <img
                style="height: 36px; margin-left: 10px"
                src="@/assets/download.png"
              />
            </div>
          </div>
        </el-button>
      </el-col>
    </el-row>

    <el-row justify="center" type="flex" style="margin-top: 20px">
      <el-col :md="13" :lg="12" :sm="12">
        <span
          style="
            margin-bottom: 30px;
            display: block;
            color: #fff;
            font-weight: bold;
          "
        >
          From time to time we update our EA. Simply come back here when we
          have<br />
          announced an update and re-download the EA.
        </span>
      </el-col>
    </el-row>

    <el-row justify="center" type="flex" style="margin-top: 50px">
      <el-col :md="20" :lg="18" :sm="22">
        <div
          style="
            background: #fff;
            height: 5px;
            border-radius: 5px;
            width: 80%;
            margin: auto;
          "
        />
        <div
          style="
            color: #00ff00;
            font-size: 60px;
            margin-bottom: 10px;
            margin-top: 10px;
          "
        >
          How To license the EA.
        </div>
        <div
          style="
            background: #fff;
            height: 5px;
            border-radius: 5px;
            width: 50%;
            margin: auto;
          "
        />
        <div style="color: #fff; margin-top: 20px; font-size: 28px">
          <div>
            The first thing you need to do is Download MT5 from the official MT5
            website:
            <u
              ><a
                style="text-decoration: none; color: #00ff00"
                href="https://www.metatrader5.com"
                target="_blank"
                >www.metatrader5.com</a
              ></u
            >
          </div>
          <div style="margin-top: 10px">
            Once done, simply login to the trading account your going to use the
            EA on.
          </div>
          <div style="margin-top: 10px">
            Next, download the Pow Banker EA using the download button above,
            then load it into the experts folder on your MT5 instance.
          </div>
          <div style="color: #00ff00; margin-top: 30px">
            Please follow the step by step guide below to license your EA.
          </div>
          <div style="color: #00ff00; margin-top: 10px">
            <u
              >NOTE: you can only use the EA on up to 5 accounts at the same
              time.</u
            >
          </div>
          <!--  -->

      







          <div style="margin-top: 60px">
            Once the EA is loaded on your MT5 terminal, go to the
            <span style="color: #00ff00">'Tools'</span> tab, click
            <span style="color: #00ff00">'Options'</span>, then navigate to the
            <span style="color: #00ff00">'Expert Advisors'</span> tab.
          </div>
          <div style="margin-top: 10px">
            Tick the check box for
            <span style="color: #00ff00">'Allow WebRequest for listed URL'</span
            >- Then Copy and paste ALL THREE of the URL's below for the news filter and the license portal. Once done, click
            <span style="color: #00ff00">'OK'</span>.
          </div>
          <div style="width: 80%; margin: auto">
            <el-form style="margin-top: 30px">
              <el-form-item>
                <el-input :readonly="true" v-model="link">
                  <el-button
                    ref="copyButtonLink"
                    slot="suffix"
                    type="primary"
                    circle
                    style="background: #000; border: 0; padding: 5px"
                    ><i class="el-icon-document" style="font-size: 18px"></i>
                  </el-button>
                </el-input>
              </el-form-item>
            </el-form>
          </div>

          <div style="width: 80%; margin: auto">
            <el-form style="margin-top: 30px">
              <el-form-item>
                <el-input :readonly="true" v-model="link2">
                  <el-button
                    ref="copyButtonLink2"
                    slot="suffix"
                    type="primary"
                    circle
                    style="background: #000; border: 0; padding: 5px"
                    ><i class="el-icon-document" style="font-size: 18px"></i>
                  </el-button>
                </el-input>
              </el-form-item>
            </el-form>
          </div>

          <div style="width: 80%; margin: auto">
            <el-form style="margin-top: 30px">
              <el-form-item>
                <el-input :readonly="true" v-model="link3">
                  <el-button
                    ref="copyButtonLink3"
                    slot="suffix"
                    type="primary"
                    circle
                    style="background: #000; border: 0; padding: 5px"
                    ><i class="el-icon-document" style="font-size: 18px"></i>
                  </el-button>
                </el-input>
              </el-form-item>
            </el-form>
          </div>
          <img
            style="margin-top: 20px; width: 80%; max-width: 721px"
            src="@/assets/imgpsh_fullsize_anim.png"
            alt=""
          />
          <!--  -->
          <div style="margin-top: 20px">
            Next, load up the pair you want to trade, change the timeframe on
            the chart to <span style="color: #00ff00">M1</span>, then simply
            drag the EA onto the chart.
          </div>
          <div style="margin-top: 10px">
            Next, click the <span style="color: #00ff00">'Inputs'</span> tab,
            select the <span style="color: #00ff00">'API KEY'</span> input box
            and enter your
            <span style="color: #00ff00">API Key.</span>
          </div>
          <div style="margin-top: 10px">
            Once you've done this, choose the settings you want to run and click
            <span style="color: #00ff00">'OK'.</span>
          </div>
          <img
            style="margin-top: 20px; width: 100%; max-width: 1159px"
            src="@/assets/doc2.png"
            alt=""
          />
          <div style="color: #00ff00; margin-top: 20px">
            NOTE: We will continue upgrading the EA so you may have a different
            version number to the above photo.
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Clipboard from "clipboard";
import banner from "./components/banner.vue";
import { userInfo } from "@/api/auth";
import { getUserAccounts, deleteAccountAPI } from "@/api/user";
import changePassword from "./components/change_password.vue";
import { downloadEAFileApi } from "@/api/file";

/* eslint-disable */
export default {
  name: "HomeView",
  components: {
    banner: banner,
    changePassword,
  },
  data() {
    return {
      apiKey: null,
      userId: null,
      status: false,
      accounts: null,
      newPasswordFormData: {
        current: null,
        newPassword: null,
        reNewPassword: null,
      },
      link: "https://license.myaccount-pow.com",
      link2: "https://nfs.faireconomy.media",
      link3: "https://greenwichmeantime.com",
    };
  },
  beforeMount() {
    this.$store.dispatch("user/getUserInfo");
  },
  mounted() {
    const clipboard = new Clipboard(this.$refs.copyButton.$el, {
      text: () => this.apiKey,
    });

    clipboard.on("success", () => {
      this.$message({
        message: "Copy successfully",
        type: "success",
        duration: 1500,
      });
    });

    const clipboard1 = new Clipboard(this.$refs.copyButtonLink.$el, {
      text: () => this.link,
    });

    clipboard1.on("success", () => {
      this.$message({
        message: "Copy successfully",
        type: "success",
        duration: 1500,
      });
    });



    const clipboard2 = new Clipboard(this.$refs.copyButtonLink2.$el, {
      text: () => this.link2,
    });

    clipboard2.on("success", () => {
      this.$message({
        message: "Copy successfully",
        type: "success",
        duration: 1500,
      });
    });


    const clipboard3 = new Clipboard(this.$refs.copyButtonLink3.$el, {
      text: () => this.link3,
    });

    clipboard3.on("success", () => {
      this.$message({
        message: "Copy  successfully",
        type: "success",
        duration: 1500,
      });
    });

    this.getUserApiKey();
  },
  methods: {
    getUserApiKey() {
      userInfo()
        .then((res) => {
          this.apiKey = res.data.apikey;
          this.userId = res.data.id;
          this.userAccounts();
        })
        .catch((e) => {
          if (e.response.status == 401) {
            this.$store.dispatch("user/logout").then(() => {
              this.$router.push("/login");
            });
          }
        });
    },
    signout() {
      this.$store.dispatch("user/logout").then(() => {
        this.$router.push("/login");
      });
    },
    download() {
      downloadEAFileApi()
        .then((res) => {
          let filename = res.headers["x-filename"];

          if (!filename) {
            filename = "PowEA.mq5";
          }

          var FILE = window.URL.createObjectURL(new Blob([res.data]));

          var docUrl = document.createElement("a");
          docUrl.href = FILE;
          docUrl.setAttribute("download", filename);
          document.body.appendChild(docUrl);
          docUrl.click();
        })
        .catch((e) => {
          this.$message.error({
            title: "Error",
            message: e.response.data.error,
            duration: 3000,
          });
        });
    },
    userAccounts() {
      getUserAccounts(this.userId)
        .then((res) => {
          this.accounts = res.data.accounts;
        })
        .catch((e) => {
          this.$message.error({
            title: "Error",
            message: e.response.data.error,
            duration: 3000,
          });
        });
    },
    onAccountDelete(id) {
      this.$confirm("Do you wish to delete this account?", {
        confirmButtonText: "YES",
        cancelButtonText: "NO",
        confirmButtonClass: "btn-confirm",
        cancelButtonClass: "btn-cancel",
        callback: (res) => {
          if (res == "confirm") {
            deleteAccountAPI(id)
              .then(() => {
                this.userAccounts();
              })
              .catch((e) => {
                this.$message.error({
                  title: "Error",
                  message: e.response.data.error,
                  duration: 3000,
                });
              });
          }
        },
      });
    },
  },
};
</script>


<style>
.btn-confirm {
  background: #ff0000 !important;
  color: #000 !important;
  font-weight: bold !important;
  border-radius: 8px !important;
  border: 0 !important;
}

.btn-cancel {
  background: #ffffff !important;
  color: #000 !important;
  font-weight: bold !important;
  border-radius: 8px !important;
  border: 1 px solid #000 !important;
}

.el-form-item__label {
  font-size: 20px !important;
  color: #fff !important;
  font-weight: bold !important;
  padding-right: 20px !important;
  float: left !important;
}

.el-input {
  border: 2px solid #00ff00 !important;
  border-radius: 8px;
  color: #fff;
}

.el-input__inner {
  font-weight: bold;
  border: 1px solid #00ff00 !important;
  border-radius: 8px;
  color: #fff !important;
  background-color: #000 !important;
  text-align: center;
  font-size: 16px !important;
}
.el-input__inner:focus {
  border-color: #00ff00 !important;
}

.el-input__inner:hover {
  border-color: #00ff00 !important;
}
.el-popover {
  left: 15px !important;
}
</style>
