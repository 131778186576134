<template>
  <div class="logo">
    <img class="w-logo" src="@/assets/w_logo.png" alt="logo" />
    <img class="pow-logo" src="@/assets/pow_logo.png" alt="logo" />
    <div class="banner">
      <span style="color: #fff">Progress. Overcome.</span
      ><span style="color: #00ff00">Win.</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "bannerView",
};
</script>


<style>
.logo {
  position: relative;
  width: 700px;
  margin-left: calc(50% - 250px);
}

.w-logo {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  width: 700px;
}

.pow-logo {
  position: absolute;
  top: 59px;
  left: 131px;
  height: 88px;
}

.banner {
  font-size: 28px;
  font-weight: bold;
  top: 150px;
  left: 120px !important;
  position: absolute;
}
</style>
