<template>
  <div class="changepassword">
    <el-form label-position="right" label-width="150px">
      <el-form-item label="Current Password:" style="margin-bottom: 0">
        <el-input type="password" v-model="newPasswordFormData.currentpassword">
        </el-input>
      </el-form-item>
      <hr style="margin: 0" />
      <el-form-item label="New Password:" style="margin-bottom: 0">
        <el-input type="password" v-model="newPasswordFormData.newpassword">
        </el-input>
      </el-form-item>
      <hr style="margin: 0" />
      <el-form-item label="Re-Enter Password:" style="margin-bottom: 0">
        <el-input type="password" v-model="newPasswordFormData.renewpassword">
        </el-input>
      </el-form-item>
      <hr style="margin-top: 0" />
      <el-form-item style="margin-bottom: 0">
        <el-button style="border-radius: 10px" @click="setNewPassword"
          >Confirm Change</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>


<script>
import { changePassword } from "@/api/auth";

export default {
  name: "changePassword",
  data() {
    return {
      newPasswordFormData: {
        currentpassword: null,
        newpassword: null,
        renewpassword: null,
      },
    };
  },
  methods: {
    setNewPassword() {
      changePassword(this.newPasswordFormData)
        .then(() => {
          this.$message.success({
            title: "Success",
            message: "Password changed successfully.",
            duration: 3000,
          });
        })
        .catch((e) => {
          this.$message.error({
            title: "Error",
            message: e.response.data.error,
            duration: 3000,
          });
        });
    },
  },
};
</script>

<style scoped>
.changepassword /deep/ .el-button {
  background: #000;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  position: relative;
  left: -30px;
}

.changepassword /deep/ .el-form-item__label {
  font-size: 15px !important;
  color: #000 !important;
  font-weight: bold !important;
  padding-right: 20px !important;
}

.changepassword /deep/ .el-input {
  border: 2px solid #ffffff !important;
  border-radius: 8px;
  color: #000;
}

.changepassword /deep/ .el-input__inner {
  font-weight: bold;
  border: 1px solid #ffffff !important;
  border-radius: 8px;
  color: #000 !important;
  background-color: rgb(255, 255, 255) !important;
}

.changepassword /deep/ .el-input__inner:focus {
  border-color: #ffffff !important;
}

.changepassword /deep/ .el-input__inner:hover {
  border-color: #ffffff !important;
}
</style>
