import request from '@/utils/request'


export function uploadEAFileApi(formData){
    return request({
        url:'file',
        method:'post',
        data:formData,
        headers: { "Content-Type": "multipart/form-data" },
    })
}

export function downloadEAFileApi(){
    return request({
        url:'file',
        method:'get',
        responseType: 'blob',
    })
}
