import axios from 'axios'
import store from '@/store'
import { getToken } from '@/utils/auth'

const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 20000 // request timeout
  });

service.interceptors.request.use(
  config =>{
    if(store.getters.token){
      config.headers['Authorization'] = 'Token ' + getToken()
    }
    return config
  }
)

export default service
