import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'


Vue.use(VueRouter)


function getToken(){
  return localStorage.getItem('token')
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/adminarea',
    name: 'admin',
    component: () => import('../views/AdminView.vue')
  },
  {
    path:'/login',
    name:'loginView',
    component: () => import('../views/LoginView.vue')
  },
  {
    path:'/reset',
    name:'resetPass',
    component: () => import('../views/ResetPasswordRequest.vue')
  },
  {
    path:'/resetpassword',
    name:'resetPassword',
    component: () => import('../views/ResetPassword.vue')
  }
]

const router = new VueRouter({
  routes
})

const public_routes = ['/resetpassword', '/login', '/reset']

router.beforeEach(async(to, from, next) => {
  const hasToken = getToken()

  if(hasToken){
    if(to.path === '/login'){
      next({path:'/'})
    }else{
      if(to.path === '/adminarea'){
        if(localStorage.getItem('su') == 'true'){
            next()
        }else{
          next({path:'/login'})
        }
      }
      if(to.path === '/'){
        if(localStorage.getItem('su') == 'true'){
          next('/adminarea')
      }else{
        next()
      }
      }
    }
  }else{
    if(public_routes.indexOf(to.path) < 0){
      next({path:'/login'})
    }else{
      next()
    }
  }

})


export default router
